<template>
  <PageContainer>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item label="手机号:">
        <el-input v-model.trim="searchForm.phone" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="登录账号:">
        <el-input v-model.trim="searchForm.loginName" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="机构:">
        <el-select v-model="searchForm.orgaId" :disabled="!!searchForm.compId" clearable filterable placeholder="选择机构">
          <el-option v-for="item in orgas" :key="item.orgaId" :value="item.orgaId" :label="item.orgaName"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="企业:">
        <el-select v-model="searchForm.compId" :disabled="!!searchForm.orgaId" clearable filterable placeholder="选择企业" >
          <el-option v-for="item in comps" :key="item.compId" :value="item.compId" :label="item.compName"></el-option>
        </el-select>
      </el-form-item>
    </BaseSearchForm>

    <BaseTable
      id="account"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData">
      <template #buttons>
        <el-button v-auth="'权限管理_账户管理_新增'" size="small" type="primary" icon="el-icon-plus" @click="editVisible=true">新增</el-button>
      </template>
      <vxe-column type="seq" title="序号" width="80" align="center"></vxe-column>
      <vxe-column field="userName" title="用户名称"></vxe-column>
      <vxe-column field="phone" title="用户手机号"></vxe-column>
      <vxe-column field="loginName" title="登录账号"></vxe-column>
      <!-- <vxe-column field="roleCodes" title="角色码"></vxe-column> -->
      <vxe-column field="roleNames" title="角色名称"></vxe-column>
      <vxe-column field="regtime" title="创建时间"></vxe-column>
      <vxe-column title="操作" width="220">
        <template #default={row}>
          <el-button v-auth="'权限管理_账户管理_编辑'" type="primary" title="编辑账号" @click="goEditAccount(row)">编辑</el-button>
          <el-button v-auth="'权限管理_账户管理_绑定角色'" type="primary" title="绑定角色" @click="goEditRole(row)">绑定角色</el-button>
          <el-button v-auth="'权限管理_账户管理_删除'" type="danger" title="删除账号" @click="deleteAccount(row.accoId)">删除</el-button>
        </template>
      </vxe-column>
    </BaseTable>

    <!-- 编辑角色 -->
    <edit-account :visible.sync="editVisible" :edit-data.sync="editData" @refresh="initList" />
    <!-- 新增角色 -->
    <bind-role :visible.sync="bindVisible" :edit-data.sync="editData" @refresh="initList" />
  </PageContainer>
</template>

<script>
import { getAccountList, delAccount } from '@/apis/authManage/account'
import { getOrgas, getComps } from '@/apis/common'
import BindRole from './components/BindRole'
import EditAccount from './components/EditAccount'

export default {
  data () {
    return {
      orgas: [],
      comps: [],
      searchForm: {
        phone: '',
        loginName: '',
        compId: '',
        orgaId: ''
      },
      // 列表相关
      loading: false,
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 50,
        totalSize: 0
      },
      // 编辑相关
      editData: null,
      editVisible: false,
      bindVisible: false
    }
  },
  created () {
    this.initList()
    this.initCompsAndOrgs()
  },
  methods: {
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },

    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },

    initList () {
      this.loading = true
      const { tablePage } = this
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      getAccountList(params).then((res) => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.data.rows.map((item) => {
            if (item.roles) {
              item.roleNames = item.roles.reduce((sum, curr) => {
                sum.push(curr.roleName)
                return sum
              }, []).join(',')
            } else {
              item.roleNames = ''
            }
            return item
          })
          tablePage.totalSize = res.data.totalSize
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },

    goEditRole (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.bindVisible = true
    },

    goEditAccount (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.editVisible = true
    },

    deleteAccount (id) {
      this.$confirm('此操作将永久删除该账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = { ids: [id] }
        delAccount(params).then((res) => {
          if (res.code) {
            this.initList()
            this.$message.success('刪除成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },

    initCompsAndOrgs () {
      getOrgas().then((res) => {
        this.orgas = res.data.rows
      })
      getComps().then((res) => {
        this.comps = res.data.rows
      })
    }
  },
  components: {
    BindRole,
    EditAccount
  }
}
</script>
