import http from '@/utils/http'

// 新建/修改账户
export function updateAccount (p) {
  return http.post('/mortconc/accocs/set', p)
}

// 获取账号列表
export function getAccountList (p) {
  return http.post('/mortconc/accocs/list', p)
}

// 删除账号
export function delAccount (p) {
  return http.post('/mortconc/accocs/del', p)
}

// 给账号绑定角色
export function bindRole (p) {
  return http.post('/mortconc/accocs/boundRole', p)
}
