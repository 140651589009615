<template>
  <el-dialog
    v-bind="{title:'绑定角色',width:'420px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">

    <el-form ref="form" label-width="130px" :model="submitForm" :rules="rules">
      <el-form-item label="当前账号" v-if="editData">
        <el-input class="w-200" v-model="editData.loginName" disabled />
      </el-form-item>
      <el-form-item label="选择角色" prop="roleIds">
        <el-drag-select class="w-200" v-model="submitForm.roleIds" multiple placeholder="请选择">
          <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId" />
        </el-drag-select>
      </el-form-item>
    </el-form>
    <p class="flex justify-center fs-14">注：系统左上角图标取【第一个有图标的角色】的图标，拖动可调整角色顺序。</p>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { bindRole } from '@/apis/authManage/account'
import { getRoleList } from '@/apis/authManage/role'
import ElDragSelect from '@/components/DragSelect' // base on element-ui
import { mapActions } from 'vuex'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    // 机构 或 企业的 id
    companyParams () {
      const userInfo = this.$store.state.account.userInfo
      const params = {}
      params.compId = userInfo.compId || undefined
      params.orgaId = userInfo.orgaId || undefined
      params.relaId = userInfo.relaId || undefined
      return params
    },
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () { return this.visible }
    }
  },
  data () {
    return {
      submitForm: {
        accoId: '',
        compId: '',
        orgaId: '',
        relaId: '',
        roleIds: []
      },
      rules: {
        roleIds: { required: true, message: '请选择角色' }
      },
      roleList: [],
      loading: false
    }
  },
  created () {
    getRoleList({
      pageNum: 1,
      pageSize: 10000
    }).then((res) => {
      if (res.code == 200) {
        this.roleList = res.data.rows
      } else {
        this.$message.error(res.msg)
      }
    })
  },
  methods: {
    ...mapActions('account', ['refreshToken']),

    submit () {
      const submitForm = this.submitForm
      const obj = {}
      obj.accoId = submitForm.accoId || undefined
      obj.orgaId = submitForm.orgaId || undefined
      obj.relaId = submitForm.relaId || undefined
      obj.compId = submitForm.compId || undefined

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          bindRole({ obj, ids: submitForm.roleIds }).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('操作成功！')
              this.$emit('refresh')
              this.visibleDialog = false
              // 刷新token和用户信息
              this.refreshToken()
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    handlerOpen () {
      const { submitForm, editData } = this
      if (editData) {
        submitForm.accoId = editData.accoId
        submitForm.compId = editData.compId || undefined
        submitForm.orgaId = editData.orgaId || undefined
        submitForm.relaId = editData.relaId || undefined
        const roles = editData.roles || []
        submitForm.roleIds = roles.map(item => item.roleId)
      }
    },

    handlerClose () {
      this.$emit('update:editData', null)
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$refs.form.resetFields()
    }
  },
  components: {
    ElDragSelect
  }
}
</script>
