<template>
  <el-dialog
    v-bind="{title:editData?'编辑账号':'新建账号',width:'420px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form ref="form" label-width="130px" :model="submitForm" :rules="rules">
      <el-form-item label="用户名称" prop="userName">
        <el-input class="w-200" v-model.trim="submitForm.userName" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="登录账号" prop="loginName">
        <el-input class="w-200" v-model.trim="submitForm.loginName" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="登录密码" prop="pwd">
        <el-input class="w-200" v-model.trim="submitForm.pwd" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="用户手机号" prop="phone">
        <el-input class="w-200" v-model.trim="submitForm.phone" clearable maxlength="11" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="关联平台" prop="plateId">
        <el-select class="w-200" v-model.trim="submitForm.plateId" filterable clearable placeholder="选择平台">
          <el-option v-for="item in platecs" :key="item.plateId" :value="item.plateId" :label="item.sysName"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="绑定机构" prop="orgaId" >
        <template #label>
          <el-select v-model="bindType" @change="submitForm.orgaId = submitForm.compId = ''">
            <el-option label="绑定企业" :value="1"></el-option>
            <el-option v-if="hasAuth('authManage:edit:orgaId')" label="绑定机构" :value="3"></el-option>
          </el-select>
        </template>

        <div v-show="bindType == 3">
          <el-select class="w-200" v-model="submitForm.orgaId" filterable clearable placeholder="选择机构">
            <el-option v-for="item in orgas" :key="item.orgaId" :value="item.orgaId" :label="item.orgaName"></el-option>
          </el-select>
        </div>
        <div v-show="bindType == 1">
          <el-select class="w-200" v-model="submitForm.compId" filterable clearable placeholder="选择企业">
            <el-option v-for="item in comps" :key="item.compId" :value="item.compId" :label="item.compName"></el-option>
          </el-select>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { updateAccount } from '@/apis/authManage/account'
import { getOrgas, getComps, getPlatecs } from '@/apis/common'
import { mapActions } from 'vuex'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    },
    permList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    }
  },
  data () {
    return {
      loading: false,
      orgas: [],
      comps: [],
      platecs: [], // 平台
      bindType: 1,
      submitForm: {
        accoId: '',
        loginName: '',
        pwd: '',
        userName: '',
        phone: '',
        orgaId: '',
        compId: '',
        plateId: ''
      },
      rules: {
        loginName: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }
        ],
        pwd: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 6, max: 16, message: '长度在 6 到 16 位' }
        ],
        userName: [
          { required: true, message: '请输入用户名称', trigger: 'blur' },
          { pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,20}$/, message: '用户名只能为汉字、字母、数字、下划线', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        phone: [
          { pattern: /^1\d{10}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.initCompsAndOrgs()
  },
  methods: {
    ...mapActions('account', ['refreshToken']),
    submit () {
      const submitForm = { ...this.submitForm }
      if (!submitForm.orgaId && !submitForm.compId) {
        let label
        label = this.bindType == 1 && '企业'
        label = this.bindType == 3 && '机构'
        return this.$message.error(`请绑定${label}`)
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          updateAccount(submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('操作成功！')
              this.$emit('refresh')
              this.visibleDialog = false
              // 刷新token和用户信息
              this.refreshToken()
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    handlerOpen () {
      const editData = this.editData
      if (editData) {
        Object.keys(this.submitForm).forEach((key) => {
          const value = editData[key]
          if (value) this.submitForm[key] = value
        })
        if (editData.orgaId) this.bindType = 3
        if (editData.compId) this.bindType = 1
      }
    },

    handlerClose () {
      this.$emit('update:editData', null)
      this.bindType = 1
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$refs.form.resetFields()
    },

    initCompsAndOrgs () {
      getOrgas().then((res) => {
        this.orgas = res.data.rows
      })
      getComps().then((res) => {
        this.comps = res.data.rows
      })
      getPlatecs().then((res) => {
        this.platecs = res.data.rows
      })
    }
  }
}
</script>
